<template>
  <div class="device-orientation screen-warning">
    <h3 class="title" v-html="this.$t('page-proper-dimensions.proper-device-tablet')" v-if="isMobileOrTablet"></h3>
    <h3 class="title" v-html="this.$t('page-proper-dimensions.proper-device-desktop')" v-else></h3>
  </div>

</template>

<script>

import {deviceTypes} from "@/constants";
import {getDeviceType} from "@/utils/utils";

export default {
  data() {
    return {
    };
  },
  methods: {getDeviceType},
  computed: {
    isMobileOrTablet() {
      console.log(getDeviceType())
      return getDeviceType() === deviceTypes.MOBILE || getDeviceType() === deviceTypes.TABLET;
    },
  },
};
</script>
